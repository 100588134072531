import { Controller } from 'stimulus'

const Uppy = require('@uppy/core')
const ActiveStorageUpload = require('@excid3/uppy-activestorage-upload')
const Informer = require('@uppy/informer')

export default class extends Controller {
  static targets = ['uploaderArea', 'preview', 'previewTemplate', 'fileInput'];

  static values = {
    multipleFiles: Boolean,
    uploadUrl: String,
    previewUrlBase: String,
    allowedFileTypes: Array,
    maxFileSizeInBytes: Number
  };

  connect () {
    this.initializeUppy()
    this.enableInformer()
    this.enableActiveStorageUpload()
  }

  disconnect () {
    this.teardown()
  }

  initializeUppy () {
    this.uppy = new Uppy({
      debug: true,
      autoProceed: true,
      allowMultipleUploads: false,
      restrictions: {
        maxFileSize: this.maxFileSizeInBytesValue,
        allowedFileTypes: this.allowedFileTypesValue
      }
    })

    this.uppy.on('file-removed', () => {
      this.fileInputTarget.value = null
    })

    this.uppy.on('complete', (result) => {
      // i have to do something with the uploaded value
      this.fileInputTarget.value = null

      result.successful.forEach((file) => {
        this.appendUploadedFile(file)
      })

      this.uppy.reset()
    })
  }

  appendUploadedFile (file) {
    const imagePreview = this.previewTemplateTarget.content.firstElementChild.cloneNode(
      true
    )

    imagePreview.getElementsByTagName(
      'img'
    )[0].src = `${this.previewUrlBaseValue}/${file.response.signed_id}/${file.response.filename}`

    imagePreview.getElementsByTagName('input')[0].value = file.response.signed_id

    if (!this.multipleFilesValue) {
      if (this.hasPreviewTarget) {
        this.previewTarget.parentNode.removeChild(this.previewTarget)
      }
    }
    this.uploaderAreaTarget.appendChild(imagePreview)
  }

  clearImage (event) {
    // const containingPreviewTarget = this.previewTargets.find(
    //   (previewTarget) => previewTarget.contains(event.target),
    // );
    // this.uploaderAreaTarget.removeChild(containingPreviewTarget);
    this.uploaderAreaTarget.removeChild(
      event.currentTarget.closest('.image-preview')
    )

    // send special value to mark the image(s) to be purged
    if (!this.hasPreviewTarget) {
      const hiddenInput = document.createElement('input')
      hiddenInput.type = 'hidden'
      hiddenInput.name = this.fileInputTarget.name
      hiddenInput.value = ''
      this.fileInputTarget.parentNode.insertBefore(
        hiddenInput,
        this.fileInputTarget.nextSibling
      )
    }
  }

  enableActiveStorageUpload () {
    this.uppy.use(ActiveStorageUpload, {
      directUploadUrl: this.uploadUrlValue
    })
  }

  enableInformer () {
    this.uppy.use(Informer, {
      target: this.uploaderAreaTarget
    })
  }

  userSelectedFile (event) {
    const files = Array.from(event.target.files)
    files.forEach((file) => {
      try {
        this.uppy.addFile({
          source: 'file input',
          name: file.name,
          type: file.type,
          data: file
        })
      } catch (err) {
        if (err.isRestriction) {
          console.log('Restriction error:', err)
        } else {
          console.error(err)
        }
      }
    })
  }

  teardown () {
    this.uppy.close()
  }
}
