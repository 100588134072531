import { Controller } from 'stimulus'

const Uppy = require('@uppy/core') // see below for the full list of locales
const XHRUpload = require('@uppy/xhr-upload') // see below for the full list of locales
const Informer = require('@uppy/informer')

export default class extends Controller {
  static targets = [
    'fakeFileInput',
    'uploadedFileInput',
    'removeImageInput',
    'previewArea',
    'previewImage',
    'clearButton'
  ];

  static values = {
    image: String,
    allowedFileTypes: Array,
    maxFileSizeInBytes: Number
  };

  connect () {
    console.log(this.imageValue, this.uploadedFileInputTarget.value)
    this.initializeUppy()
    this.enableInformer()
    this.enableXHRUpload()
  }

  disconnect () {}

  initializeUppy () {
    this.uppy = new Uppy({
      debug: true,
      autoProceed: true,
      allowMultipleUploads: false,
      restrictions: {
        maxFileSize: this.maxFileSizeInBytesValue,
        minNumberOfFiles: 1,
        maxNumberOfFiles: 1,
        allowedFileTypes: this.allowedFileTypesValue
      }
    })

    this.uppy.on('file-removed', () => {
      this.fakeFileInputTarget.value = null
    })

    this.uppy.on('complete', () => {
      this.fakeFileInputTarget.value = null
    })
  }

  enableInformer () {
    this.uppy.use(Informer, {
      target: document.body
    })
  }

  enableXHRUpload () {
    this.uppy.use(XHRUpload, {
      // shrine upload
      endpoint: '/upload'
    })

    this.uppy.on('upload-success', (file, response) => {
      const fileData = JSON.stringify(response.body.data)

      // set hidden field value to the uploaded file data so that it's submitted with the form
      this.uploadedFileInputTarget.value = fileData
      this.imageValue = response.body.url
      this.uppy.reset()
    })
  }

  addFile (event) {
    const files = Array.from(event.target.files)
    files.forEach((file) => {
      try {
        this.uppy.addFile({
          source: 'file input',
          name: file.name,
          type: file.type,
          data: file
        })
      } catch (err) {
        if (err.isRestriction) {
          console.log('Restriction error:', err)
        } else {
          console.error(err)
        }
      }
    })
  }

  imageValueChanged () {
    if (this.imageValue === '') {
      this.previewAreaTarget.classList.add('hidden')
    } else {
      this.previewImageTarget.src = this.imageValue
      this.previewAreaTarget.classList.remove('hidden')
    }
  }

  clearImage () {
    this.imageValue = ''
    this.removeImageInputTarget.checked = true
  }

  teardown () {
    this.uppy.close()
  }
}
