import { Controller } from 'stimulus'
import ApexCharts from 'apexcharts'

export default class extends Controller {
  static targets = ['searchForm']

  static values = {
    chartId: String
  }

  disconnect () {
    // ApexCharts.exec(this.chartIdValue, 'destroy')
  }
}
