import { Controller } from 'stimulus'
import 'lightgallery.js'

const removeElements = (elms) => elms.forEach((el) => el.remove())

export default class extends Controller {
  static values = {
    options: Object
  }

  connect () {
    this.forceRemove()
    lightGallery(this.element, {
      ...this.defaultOptions,
      ...this.optionsValue
    })
  }

  disconnect () {
    this.teardown()
  }

  teardown () {
    if (this.lightGallery) {
      this.lightGallery.destroy(true)
      this.forceRemove()
    }
  }

  forceRemove () {
    // this is needed to work with back/forward
    removeElements(document.querySelectorAll('.lg-backdrop'))
    removeElements(document.querySelectorAll('.lg-outer'))
    document.body.classList.remove('lg-on')
  }

  get lightGallery () {
    return window.lgData[this.element.getAttribute('lg-uid')]
  }

  get defaultOptions () {
    return {}
  }
}
