import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['option', 'input']
  static classes = ['active', 'inactive']

  connect () {
  }

  selectRadioOption (event) {
    this.optionTargets.forEach((el, i) => {
      const applyActiveClassElement = el.getElementsByClassName('apply-active-class')[0] || el
      if (event.currentTarget === el) {
        applyActiveClassElement.classList.add(this.activeClass)
        applyActiveClassElement.classList.remove(this.inactiveClass)
        this.inputTarget.value = el.dataset.optionId
      } else {
        applyActiveClassElement.classList.add(this.inactiveClass)
        applyActiveClassElement.classList.remove(this.activeClass)
      }
    })
  }
}
