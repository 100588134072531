import { Controller } from 'stimulus'

import 'ion-rangeslider/js/ion.rangeSlider'

// easily dispatch beautifully named events that can be catched from another controller
import { useDispatch } from 'stimulus-use'

export default class extends Controller {
  static targets = ['input', 'customFromLabel', 'customToLabel'];

  static values = {
    from: Number,
    to: Number,
    cacheCurrentValue: Boolean
  };

  initialize () {
    this.originalFrom = this.fromValue
    this.originalTo = this.toValue
  }

  connect () {
    useDispatch(this)
    this.setupRangeSlider()
  }

  setupRangeSlider () {
    $(this.inputTarget).ionRangeSlider({
      from: this.fromValue,
      to: this.toValue,
      onStart: (data) => {
        if (this.hasCustomFromLabelTarget && data.from_pretty) {
          this.customFromLabelTarget.innerHTML = data.from_pretty
          this.customToLabelTarget.innerHTML = data.to_pretty
        }
      },
      onChange: (data) => {
        if (this.hasCustomFromLabelTarget) {
          this.customFromLabelTarget.innerHTML = data.from_pretty
          this.customToLabelTarget.innerHTML = data.to_pretty
        }
      },
      onFinish: (data) => {
        // case 2: Normal form -> Add from and to input
        if (data.from !== this.fromValue || this.toValue !== data.to) {
          this.sliderDataChanged(data)
        }
      }
    })

    this.rangeSlider = $(this.inputTarget).data('ionRangeSlider')
  }

  sliderDataChanged (data, dispatchEvent = true) {
    this.fromValue = data.from
    this.toValue = data.to
    // TODO: remove range input so that we won't send extraneous data or handle it serverside and don't send three inputs
    if (dispatchEvent) {
      this.dispatch('changed', { from: this.fromValue, to: this.toValue })
    }
  }

  disconnect () {
    this.teardown()
  }

  teardown () {
    if (!this.cacheCurrentValue) {
      this.fromValue = this.originalFrom
      this.toValue = this.originalTo
    }
    // or reset it?
    this.rangeSlider.destroy()
  }
}
