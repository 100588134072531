import { Controller } from 'stimulus'
import { useDebounce } from 'stimulus-use'

export default class extends Controller {
  static targets = ['input']
  static values = {
    autoSubmit: Boolean
  }

  static debounces = [{ name: 'submitForm', wait: '500' }]

  initialize () {
    // hide arrows
    this.inputTarget.classList.add('quantity-input')
  }

  connect () {
    useDebounce(this)
  }

  increaseValue (event) {
    const newValue = this.currentInputValue + this.step
    if (newValue > this.max) { return }

    this.currentInputValue = newValue
  }

  decreaseValue (event) {
    const newValue = this.currentInputValue - this.step
    if (newValue < this.min) { return }

    this.currentInputValue = newValue
  }

  submitForm (event) {
    if (this.autoSubmitValue && this.inputTarget.form !== undefined) {
      this.inputTarget.form.submitButton.click()
    }
  }

  get min () {
    if (this.inputTarget.value === undefined) {
      return Number.NEGATIVE_INFINITY
    }

    return Number(this.inputTarget.min)
  }

  get max () {
    if (this.inputTarget.value === undefined) {
      return Number.POSITIVE_INFINITY
    }

    return Number(this.inputTarget.max)
  }

  get step () {
    return Number(this.inputTarget.step) || 1
  }

  get currentInputValue () {
    return Number(this.inputTarget.value)
  }

  set currentInputValue (newValue) {
    this.inputTarget.value = newValue
    if (this.autoSubmitValue) {
      this.submitForm()
    }
  }
}
