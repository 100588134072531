import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['source', 'target'];

  connect () {
    console.log(this.targetTargets)
  }

  handleSelectChange () {
    this.populateSelect(this.sourceTarget.value)
  }

  populateSelect (sourceId, targetId = null) {
    fetch(`/${this.data.get('sourceRoutePart')}/${sourceId}/${this.data.get('targetRoutePart')}`, {
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        return response.json()
      })

      .then(data => {
        console.log(data)
        this.targetTargets.forEach((target) => {
          const selectBox = target
          selectBox.innerHTML = ''

          data.forEach(item => {
            const opt = document.createElement('option')
            opt.value = item.id
            opt.innerHTML = item[this.data.get('displayAttribute')]
            opt.selected = parseInt(targetId) === item.id
            selectBox.appendChild(opt)
          })
        })
      }).catch(function () {
        console.log('Promise Rejected')
      })
  }
}
