// javascript/controllers/select_location_controller.js
/* global google */
import { Controller } from 'stimulus'

const defaultLocation = { latitude: 37, longitude: 23 }

export default class extends Controller {
  // State/Variables that control the UI
  static values = {
    location: Object,
    zoom: Number,
    showMarker: Boolean,
    markerIconUrl: String,
    options: Object
  };

  initialize () {
    this.locationValue = {
      latitude: this.locationValue.latitude || defaultLocation.latitude,
      longitude: this.locationValue.longitude || defaultLocation.longitude
    }
  }

  connect () {
    if (!this.isPreview) {
      if (this.isGoogleMapsServicesAvailable) {
        this.initGoogleMap()
      }
    }
  }

  initGoogleMap () {
    this.map()
    this.marker()
    this.updateMapUI()
  }

  // init map object
  map () {
    if (this._map === undefined) {
      this._map = new google.maps.Map(this.element, {
        center: this.locationLatLng,
        zoom: this.zoomValue,
        ...this.optionsValue
      })
    }
    return this._map
  }

  // init marker object
  marker () {
    if (this._marker === undefined) {
      this._marker = new google.maps.Marker({
        map: this.map(),
        anchorPoint: new google.maps.Point(0, 0),
        icon: this.markerIconUrlValue
      })
    }
    return this._marker
  }

  // stimulus 2.0 values api.
  // react to the new value of location object and update UI
  locationValueChanged () {
    this.updateMapUI()
  }

  // update user interface based on the new value of location object - stimulus 2 values api
  // location value is the single source of truth for the selected location/
  // when the value changes then every ui related information is updated as a result.
  updateMapUI () {
    if (
      !this.isGoogleMapsServicesAvailable ||
      !this.isMapAvailable ||
      !this.isMarkerAvailable
    ) {
      return
    }
    if (this.showMarkerValue) {
      this.marker().setPosition(this.locationLatLng)
      this.marker().setVisible(true)
    }
  }

  get locationLatLng () {
    return {
      lat: parseFloat(this.locationValue.latitude),
      lng: parseFloat(this.locationValue.longitude)
    }
  }

  get isGoogleMapsServicesAvailable () {
    return typeof google !== 'undefined'
  }

  get isMapAvailable () {
    return this.isGoogleMapsServicesAvailable && this.map() !== 'undefined'
  }

  get isMarkerAvailable () {
    return this.isGoogleMapsServicesAvailable && this.marker() !== 'undefined'
  }

  // get isPreview() {
  //   return document.documentElement.hasAttribute('data-turbo-preview');
  // }
  //
  // get defaultOptions() {
  //   return {};
  // }
}
