import { Controller } from 'stimulus'
import QrScanner from 'qr-scanner'

import QrScannerWorkerPath from '!!file-loader!/node_modules/qr-scanner/qr-scanner-worker.min.js'
QrScanner.WORKER_PATH = QrScannerWorkerPath

export default class extends Controller {
  static values = {
    cameraAvailable: Boolean,
    scannedCode: String
  }

  static targets = ['input', 'video', 'startButton', 'endButton',
    'camQrResult', 'qrScannerUiMenu', 'scanningArea', 'cameraUnavailble']

  connect () {
    QrScanner.hasCamera().then(hasCamera => { this.cameraAvailableValue = hasCamera })
  }

  disconnect () {
    this.qrScanner.destroy()
  }

  cameraAvailableValueChanged () {
    if (this.cameraAvailableValue) {
      this.qrScannerUiMenuTarget.classList.remove('hidden')
      this.cameraUnavailbleTarget.classList.add('hidden')
    } else {
      this.qrScannerUiMenuTarget.classList.add('hidden')
      this.cameraUnavailbleTarget.classList.remove('hidden')
    }
  }

  scannedCodeValueChanged (event) {
    this.camQrResultTargets.forEach(el => { el.innerText = this.sensorNodeId })
    this.inputTargets.forEach(el => { el.value = this.sensorNodeId })
  }

  startScanning (event) {
    this.qrScanner.start()
  }

  stopScanning (event) {
    this.qrScanner.stop()
  }

  get qrScanner () {
    if (this._qrScanner === undefined) {
      this._qrScanner = new QrScanner(
        this.videoTarget,
        result => {
          this.scannedCodeValue = result
          this.stopScanning()
        }, error => {
          console.log(`Error scanning: ${error}`)
        }
      )
    }
    return this._qrScanner
  }

  get sensorNodeId () {
    try {
      const url = new URL(this.scannedCodeValue)
      const searchParams = url.searchParams
      return searchParams.get('sensor_node_id') || searchParams.get('sensor[sensor_node_id]') // #  || searchParams.get// true
    } catch (err) {
      return this.scannedCodeValue
    }
  }
}
