import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['video'];

  static values = {
    autoplay: Boolean
  };

  connect () {
    if (this.autoplayValue) {
      this.videoTarget.autoplay = this.autoplayValue
      this.videoTarget.muted = !!this.autoplayValue
    }
    this.videoTarget.load()
  }

  disconnect () {
    this.teardown()
  }

  teardown () {
    this.videoTarget.removeAttribute('autoplay')
  }

  get isPreview () {
    return document.documentElement.hasAttribute('data-turbo-preview')
  }
}
