import Flatpickr from 'stimulus-flatpickr'

// you can also import a translation file
import { Greek } from 'flatpickr/dist/l10n/gr'
import { english } from 'flatpickr/dist/l10n/default'

import isValid from 'date-fns/isValid'
import formatISO from 'date-fns/formatISO'
import parse from 'date-fns/parse'

// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends Flatpickr {
  initialize () {
    const locale = this.element.getAttribute('data-locale')
    const language = locale === 'en' ? english : Greek

    this.config = {
      time_24hr: true,
      noCalendar: true,
      enableTime: true,
      locale: language
    }
  }

  // all flatpickr hooks are available as callbacks in your Stimulus controller
  change (selectedTimes, dateStr, instance) {
    const updateChartID = this.inputTarget.getAttribute('data-chart-id')
    const dateTimePickers = this.element.parentElement

    const selectedDate = parse(
      dateTimePickers.querySelector('.day-picker').querySelector('input').value,
      'dd-MM-y',
      new Date()
    )

    const startDatetime = parse(
      dateTimePickers.querySelector('.start-time-picker').querySelector('input')
        .value,
      'HH:mm',
      selectedDate
    )

    const endDatetime = parse(
      dateTimePickers.querySelector('.end-time-picker').querySelector('input')
        .value,
      'HH:mm',
      selectedDate
    )

    if (
      updateChartID != null &&
      isValid(startDatetime) &&
      isValid(endDatetime)
    ) {
      const chart = Chartkick.charts[updateChartID]
      const myData = {
        start_datetime: formatISO(startDatetime),
        end_datetime: formatISO(endDatetime)
      }
      if (chart != null) {
        const locationNodePathName = chart.getDataSource().split('?')[0]
        chart.updateData(
          `${locationNodePathName}?${new URLSearchParams(myData).toString()}`
        )
      } else {
        console.log(
          `can not find chart with the spacific id: ${updateChartID}`
        )
      }
    }
  }
}
