import Flatpickr from 'stimulus-flatpickr'

// import rangePlugin from 'flatpickr/dist/plugins/rangePlugin.js'

// you can also import a translation file
import { Greek } from 'flatpickr/dist/l10n/gr'
import { english } from 'flatpickr/dist/l10n/default'

// import a theme (could be in your main CSS entry too...)
// import 'flatpickr/dist/themes/dark.css'
import isValid from 'date-fns/isValid'
import formatISO from 'date-fns/formatISO'
import parse from 'date-fns/parse'

// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends Flatpickr {
  initialize () {
    const locale = this.element.getAttribute('data-locale')
    const language = locale === 'en' ? english : Greek

    this.config = {
      monthSelectorType: 'static',
      dateFormat: 'd-m-Y',
      locale: language
    }
  }

  // all flatpickr hooks are available as callbacks in your Stimulus controller
  change (selectedDates, dateStr, instance) {
    const updateChartID = this.inputTarget.getAttribute('data-chart-id')

    const dateTimePickers = this.element.parentElement

    const selectedDate = selectedDates[0]

    const startDatetime = parse(
      dateTimePickers.querySelector('.start-time-picker').querySelector('input')
        .value,
      'HH:mm',
      selectedDate
    )

    const endDatetime = parse(
      dateTimePickers.querySelector('.end-time-picker').querySelector('input')
        .value,
      'HH:mm',
      selectedDate
    )

    if (
      updateChartID != null &&
      isValid(startDatetime) &&
      isValid(endDatetime)
    ) {
      const chart = Chartkick.charts[updateChartID]
      const myData = {
        start_datetime: formatISO(startDatetime),
        end_datetime: formatISO(endDatetime)
      }
      if (chart != null) {
        const locationNodePathName = chart.getDataSource().split('?')[0]
        chart.updateData(
          `${locationNodePathName}?${new URLSearchParams(myData).toString()}`
        )
      } else {
        console.log(
          `can not find chart with the spacific id: ${updateChartID}`
        )
      }
    }
  }
}
