// javascript/controllers/select_location_controller.js
/* global google */
import { Controller } from 'stimulus'

const defaultLocation = { latitude: 37, longitude: 23 }

export default class extends Controller {
  static targets = ['place', 'map', 'latitude', 'longitude'];

  // State/Variables that control the UI
  static values = {
    location: Object,
    markerPositionLocked: Boolean,
    zoom: Number,
    smoothPanning: Boolean
  };

  initialize () {
    this.locationValue = {
      latitude: this.locationValue.latitude || defaultLocation.latitude,
      longitude: this.locationValue.longitude || defaultLocation.longitude
    }
  }

  connect () {
    if (!this.isPreview) {
      if (this.isGoogleMapsServicesAvailable) {
        this.initGoogleMap()
      }
    }
  }

  initGoogleMap () {
    this.map()
    this.marker()
    this.autocomplete()
    this.updateMapUI()
  }

  // init map object
  map () {
    if (this._map === undefined) {
      this._map = new google.maps.Map(this.mapTarget, {
        center: this.latLng,
        zoom: this.zoomValue || 10
      })
    }
    return this._map
  }

  resizeMap () {
    if (this.map === undefined) {
      return
    }
    google.maps.event.trigger(this.map, 'resize')
  }

  // init marker object
  marker () {
    if (this._marker === undefined) {
      this._marker = new google.maps.Marker({
        position: this.latLng,
        map: this.map(),
        anchorPoint: new google.maps.Point(0, 0)
      })
    }
    if (this.markerPositionLockedValue) {
      this.lockMarkerPosition()
    } else {
      this.unlockMarkerPosition()
    }
    return this._marker
  }

  lockMarkerPosition () {
    if (!this.isMapAvailable) {
      return
    }
    google.maps.event.clearListeners(this.map(), 'click')
  }

  unlockMarkerPosition () {
    if (!this.isMapAvailable) {
      return
    }

    this.map().addListener('click', (event) => {
      this.locationValue = {
        latitude: event.latLng.lat(),
        longitude: event.latLng.lng()
      }
    })
  }

  toggleMarkerLocked () {
    this.markerPositionLockedValue = !this.markerPositionLockedValue
  }

  markerPositionLockedValueChanged () {
    if (this.markerPositionLockedValue) {
      this.lockMarkerPosition()
    } else {
      this.unlockMarkerPosition()
    }
  }

  // init autocomplete object
  autocomplete () {
    if (this._autocomplete === undefined) {
      this._autocomplete = new google.maps.places.Autocomplete(
        this.placeTarget
      )
      this._autocomplete.bindTo('bounds', this.map())
      this._autocomplete.setFields([
        'address_components',
        'geometry',
        'icon',
        'name'
      ])
      this._autocomplete.addListener(
        'place_changed',
        this.placeChanged.bind(this)
      )
    }
    return this._autocomplete
  }

  // user selected a new google place from autocomplete search field
  placeChanged () {
    const place = this.autocomplete().getPlace()
    if (!place.geometry) {
      // User entered the name of a Place that was not suggested and
      // pressed the Enter key, or the Place Details request failed.
      window.alert(`No details available for input: '${place.name}'`)
      return
    }
    this.map().fitBounds(place.geometry.viewport)

    this.locationValue = {
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng()
    }
  }

  // Locate user using browser's native user location
  geolocate () {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.locationValue = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          }
        },
        () => {
          window.alert('Error: The Geolocation service failed.')
        }
      )
    } else {
      // Browser doesn't support Geolocation
      window.alert('Error: Your browser does not support geolocation')
    }
  }

  // update location coordinates on input change event
  updateLocationFromUserInput () {
    this.locationValue = {
      latitude: this.latitudeTarget.value,
      longitude: this.longitudeTarget.value
    }
  }

  // stimulus 2.0 values api.
  // react to the new value of location object and update UI
  locationValueChanged () {
    this.updateMapUI()
    this.latitudeTarget.value = this.locationValue.latitude
    this.longitudeTarget.value = this.locationValue.longitude
  }

  // update user interface based on the new value of location object - stimulus 2 values api
  // location value is the single source of truth for the selected location/
  // when the value changes then every ui related information is updated as a result.
  updateMapUI () {
    if (
      !this.isGoogleMapsServicesAvailable ||
      !this.isMapAvailable ||
      !this.isMarkerAvailable
    ) {
      return
    }
    // if (this.smoothPanningValue) {
    //   this.map().panTo(this.latLng);
    // } else {
    //   this.map().setCenter(this.latLng);
    // }
    this.marker().setPosition(this.latLng)
    this.marker().setVisible(true)

    this.latitudeTarget.value = this.locationValue.latitude
    this.longitudeTarget.value = this.locationValue.longitude
  }

  get latLng () {
    return {
      lat: parseFloat(this.locationValue.latitude),
      lng: parseFloat(this.locationValue.longitude)
    }
  }

  get isGoogleMapsServicesAvailable () {
    return typeof google !== 'undefined'
  }

  get isMapAvailable () {
    return this.isGoogleMapsServicesAvailable && this.map() !== 'undefined'
  }

  get isMarkerAvailable () {
    return this.isGoogleMapsServicesAvailable && this.marker() !== 'undefined'
  }

  get isPlacesServiceAvailable () {
    return (
      this.isGoogleMapsServicesAvailable && this.autocomplete() !== 'undefined'
    )
  }

  get isPreview () {
    return document.documentElement.hasAttribute('data-turbo-preview')
  }

  preventSubmit (e) {
    if (e.key === 'Enter') {
      e.preventDefault()
    }
  }
}
