import Flatpickr from 'stimulus-flatpickr'

import { Greek } from 'flatpickr/dist/l10n/gr.js'
import { english } from 'flatpickr/dist/l10n/default.js'

import startOfDay from 'date-fns/startOfDay'
import endOfDay from 'date-fns/endOfDay'
import formatISO from 'date-fns/formatISO'

// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends Flatpickr {
  static targets = ['fromInput', 'toInput'];

  initialize () {
    const locale = this.element.getAttribute('data-locale')
    const language = locale === 'en' ? english : Greek

    this.config = {
      monthSelectorType: 'static',
      mode: 'range',
      dateFormat: 'd-m-Y',
      locale: language
    }
  }

  // all flatpickr hooks are available as callbacks in your Stimulus controller
  change (selectedDates, dateStr, instance) {
    if (selectedDates.length < 2) {
      return
    }

    const startDate = selectedDates[0]
    const endDate = selectedDates[1]

    const startDatetime = startOfDay(startDate)
    const endDatetime = endOfDay(endDate)

    if (this.hasFromInputTarget && this.hasToInputTarget) {
      this.fromInputTarget.value = formatISO(startDatetime)
      this.toInputTarget.value = formatISO(endDatetime)
      const event = new Event('change')
      console.log(event)
      this.toInputTarget.dispatchEvent(event, { bubbles: true })
    }
  }
}
