import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['container'];

  static values = {
    allowBackgroundClose: Boolean,
    preventDefaultActionOpening: Boolean,
    preventDefaultActionClosing: Boolean
  };

  static classes = ['toggled'];

  disconnect () {
    this.close()
  }

  open (e) {
    // Lock the scroll and save current scroll position
    e.preventDefault()

    // Unhide the modal
    this.containerTarget.classList.remove(this.toggledClass)
  }

  close (e) {
    if (e && this.preventDefaultActionClosing) {
      e.preventDefault()
    }

    // Hide the modal
    this.containerTarget.classList.add(this.toggledClass)
  }

  closeBackground (e) {
    if (this.allowBackgroundClose && e.target === this.containerTarget) {
      this.close(e)
    }
  }

  closeWithKeyboard (e) {
    if (
      e.keyCode === 27 &&
      !this.containerTarget.classList.contains(this.toggledClass)
    ) {
      this.close(e)
    }
  }
}
