import { Controller } from 'stimulus'
import ApexCharts from 'apexcharts'

export default class extends Controller {
  static targets = ['searchForm']

  static values = {
    chartId: String,
    url: String,
    loadOnConnect: Boolean
  }

  connect () {
    if (this.loadOnConnectValue) {
      this.fetchChartData()
    }
  }

  disconnect () {
  }

  fetchChartData (e) {
    const controller = this
    if (e !== undefined) {
      e.preventDefault()
    }

    Rails.ajax({
      url: controller.urlValue,
      data: controller.searchParams,
      type: 'GET',
      success: function (data) {
        ApexCharts.exec(controller.chartIdValue, 'updateSeries', data)
      },
      error: function (data) {
        controller.chartError(data.error, true)
      }
    })
  }

  get searchParams () {
    const formData = new FormData(this.searchFormTarget) // eslint-disable-line
    const params = new URLSearchParams(formData)
    return params.toString()
  }
}
