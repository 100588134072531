import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['toggleable', 'rotateable'];

  static values = { open: Boolean };

  static classes = ['toggled', 'rotated'];

  connect () {}

  toggle (event) {
    event.preventDefault()
    this.openValue = !this.openValue
  }

  openValueChanged () {
    this.toggleableTargets.forEach((target) => {
      if (this.openValue) {
        target.classList.remove(this.toggledClass)
      } else {
        target.classList.add(this.toggledClass)
      }
    })

    this.rotateableTargets.forEach((target) => {
      if (this.openValue) {
        target.classList.add(this.rotatedClass)
      } else {
        target.classList.remove(this.rotatedClass)
      }
    })
  }
}
