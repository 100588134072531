import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['searchForm']

  static values = {
    chartIds: Array,
    url: String,
    loadOnConnect: Boolean
  }

  connect () {
    if (this.loadOnConnectValue) {
      this.fetchChartData()
    }
  }

  disconnect () {
    this.chart.destroy()
  }

  fetchChartData (e) {
    if (e !== undefined) {
      e.preventDefault()
    }

    if (this.charts.length !== 0) {
      this.charts.forEach((chart) => {
        chart.updateData(`${this.urlValue}?${this.searchParams.toString()}`)
      })
    } else {
      console.log(
            `can not find chart with the spacific id: ${this.chartId}`
      )
    }
  }

  get charts () {
    if (this._chart === undefined) {
      this._charts = []
      this.chartIdsValue.forEach((id) => {
        this._charts.push(Chartkick.charts[id])
      })
    }
    return this._charts
  }

  get searchParams () {
    const formData = new FormData(this.searchFormTarget) // eslint-disable-line
    const params = new URLSearchParams(formData)
    return params
  }
}
