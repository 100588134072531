import { Controller } from 'stimulus'

import { tns } from 'tiny-slider/src/tiny-slider'

export default class extends Controller {
  static targets = ['container', 'controlsContainer'];

  static values = {
    options: Object
  };

  connect () {
    const elementOptions = {
      container: this.hasContainerTarget ? this.containerTarget : null,
      controlsContainer: this.hasControlsContainerTarget
        ? this.controlsContainerTarget
        : null
    }

    const tinySliderOptions = Object.assign(elementOptions, this.optionsValue)

    this.slider = tns(tinySliderOptions)
  }

  teardown () {
    this.slider.destroy()
  }
}
